html {
  height: 100%
}
body {
  /*background-image: linear-gradient(#000, #231917);*/
  /*background-repeat: no-repeat;*/
  /*background-color: #231917;*/
  background-color: #000;
  height: 100%;
  font-family: Poppins, sans-serif;
}

#root {
  height: 100%;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}